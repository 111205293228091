<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Recargas en la Estación'">
          <div class="row">
            <div class="col-md-6">
                <canvas id="product-chart" width="100%" height="100%"></canvas>            
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                  <thead>
                    <tr>                   
                      <th scope="col">Monto</th>
                      <th scope="col">Cantidad de Veces Recargada</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in datatopup">                     
                      <td>{{item.amount}}</td>
                      <td>{{item.cant}}</td>
                     
                    </tr>
                  
                  </tbody>
                </table>


            </div>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>
<script>

</script>
<script>


import Vue from "vue";
import Chart from 'chart.js';
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";

export const ProductChartData = {
  type: 'doughnut',
  data: {
    labels: [],
    datasets: [
      {         
        data: [],
        backgroundColor: [],       
        borderWidth: 3
      },     
    ]
  },
  options: {     
    responsive: true,
    lineTension: 1,
    legend: {
        display: false
    },
    legend: {
        display: false
    },   
  }
}


export default {
  name: "new-station",
  components: {
    SpringSpinner
  },
   data() {
    return {
      productoptions: null,
      id_product_station: "",
      price: "",
      vat: "",
      datatopup:null,
      idproduct: "",
      ProductChartData: ProductChartData,
      active: 0,      
    };
  },
  mounted() {
   

    var id = this.$route.params.idstation;
      this.loadTopupAnalyticByStation(id)
      .then((data) => {
         
        this.datatopup = data;       
        
         data.forEach(function(element) {          
            
          ProductChartData.data.labels.push(element.amount);
          ProductChartData.data.datasets[0].data.push(element.cant);         
          ProductChartData.data.datasets[0].backgroundColor.push(getRandomColor());        
         });
         
       this.createChart('product-chart', ProductChartData);
      })
      .catch(data => {
        this.error = data.message
      })
   
  },
  computed: {

  },
 
  methods: {
    ...mapActions([
      ,
      "updateProductStation",
      "addToastMessage",
      "loadTopupAnalyticByStation"
    ]), 
     createChart(chartId, chartData) {
    const ctx = document.getElementById(chartId);
    const myChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  },
    getRandomColor: function() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
},
    backPage: function() {
      this.$router.go(-1);
    },
  
  }
};

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
